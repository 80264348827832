<template>
  <section>
    <div>
      <el-button type="primary" size="small" @click="dialogVisible = true">新增</el-button>
    </div>
    <div class="main-content">
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="name" label="疫苗类型"> </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" style="color: red" @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form>
          <el-form-item label="疫苗类型" label-width="80px"> 
              <el-input placeholder="请输入" size="small" v-model="value"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { VaccinesType,Del,Save } from "@/api/system/vaccine";
import page from "@/mixins/page";
export default {
  data() {
    return {
      tableData: [],
      query: {
        ps: 20,
      },
      total: 0,
      dialogVisible:false,
      value:''
    };
  },
  mixins: [page],
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let res = await VaccinesType();
      this.tableData = res.data.data;
      this.total = res.data.data.length;
    },
    del(id) {
      this.$confirm("确认删除？", "提示").then(async () => {
          let res = await Del(id)
          this.$message.success('删除成功')
          this.getList()
      });
    },
    async save(){
       let res = await Save({name:this.value})
       this.value = ''
       this.$message.success('添加成功')
       this.dialogVisible = false
       this.getList()
    },
  },
};
</script>

<style>
</style>