import fetch from "../../utils/fetch";

export function VaccinesType() {
  return fetch({
    url: "/vaccinesType/list",
    method: "GET",
  });
}

export function Del(id) {
  return fetch({
    url: `/vaccinesType/${id}/del`,
    method: "DELETE",
  });
}

export function Save(params) {
  return fetch({
    url: `/vaccinesType/save`,
    method: "POST",
    data:params
  });
}
